import React from 'react';
import { Flex, color } from 'deepstash-ui';
import ValuePropItem from './ValuePropItem';
import LANDING_ANIMATION_1 from 'theme/animations/landing_1.json';
import LANDING_ANIMATION_2 from 'theme/animations/landing_2.json';
import LANDING_ANIMATION_3 from 'theme/animations/landing_3.json';
import LandingIllustration1 from '../illustrations/LandingIllustration1';
import LandingIllustration2 from '../illustrations/LandingIllustration2';
import LandingIllustration3 from '../illustrations/LandingIllustration3';
import FullWidthSectionWrapper from 'src/components/layout/components/FullWidthSectionWrapper';

interface LandingValuePropsSectionProps {}

const LandingValuePropsSection: React.FC<
  LandingValuePropsSectionProps
> = ({}) => {
  return (
    <Flex
      flexDirection="column"
      // paddingX={LANDING_PAGE_DEFAULT_PX}
      py={{ sm: 40, md: 80 }}
      my={24}
      gridRowGap={{ sm: 48, md: 80 }}
      bgColor={color.light.surface}
    >
      <FullWidthSectionWrapper
        containerProps={{
          flexDirection: 'column',
          gridRowGap: { sm: 48, md: 80 },
        }}
      >
        <ValuePropItem
          preTitle={'Discover'}
          title={'Your Next Big Idea'}
          subtitle={
            <>
              Ideas come from books, articles, podcasts & more.
              <br />
              We make it <strong>easy</strong> by picking the best ideas from a{' '}
              <strong>200,000+ catalogue</strong> on thousands of topics.
            </>
          }
          direction={'left'}
          startingIllustration={<LandingIllustration1 position="absolute" />}
          lottieAnimationData={LANDING_ANIMATION_1}
        />
        <ValuePropItem
          preTitle={'Learn Anything'}
          title={'Remember Everything'}
          subtitle={
            <>
              The idea format helps <strong>3M+ people</strong> remember the
              ideas that <strong>help them grow</strong>. Join the community.
              Save and remember ideas. Share & discover.
            </>
          }
          direction={'right'}
          startingIllustration={<LandingIllustration2 position="absolute" />}
          lottieAnimationData={LANDING_ANIMATION_2}
        />
        <ValuePropItem
          preTitle={'Get Creative'}
          title={'Build a Reading Habit'}
          subtitle={
            <>
              "Creativity is just connecting the dots." -{' '}
              <strong>Steve Jobs.</strong>
              <br />
              With Deepstash, you will build stronger{' '}
              <strong>reading habits</strong> through{' '}
              <strong>bite-sized recommendations</strong> throughout the day.
            </>
          }
          direction={'left'}
          startingIllustration={<LandingIllustration3 position="absolute" />}
          lottieAnimationData={LANDING_ANIMATION_3}
        />
      </FullWidthSectionWrapper>
    </Flex>
  );
};

export default LandingValuePropsSection;
